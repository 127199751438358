import ErrorPage from '../src/components/Pages/ErrorPage';

export default ErrorPage;

export async function getServerSideProps(ctx) {
  return {
    props: {
      statusCode: ctx.res ? ctx.res.statusCode : ctx.res,
    },
  };
}
