import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Navigation from '../../Navigation';
import Footer from '../../Footer';
import SeoHead from '../../SeoHead';
import Authorization from '../../Authorization';
import ModalDeliveryDay from '../../ModalDeliveryDay';
import NavigationMobile from '../../Navigation/NavigationMobile/NavigationMobile';
import MobileNavigationTabs from '../../MobileNavigationTabs/MobileNavigationTabs';
import FarmerRegistration from '../../Farmers/FarmerRegistration/FarmerRegistration';
import GEO from '../../GEO/GEO';
import styles from './Layout.module.scss';
import { getCookie } from '../../../lib/cookie';
import { COOKIE } from '../../../constants/COOKIE';
import Emmiter from '../../../lib/emmiter';
import EVENTS from '../../../constants/EVENTS';
import { useRouter } from 'next/router';

const Layout = ({ searchInput, children, noFooter, seo, mobileHeader, searchPage }) => {
  const router = useRouter();

  useEffect(() => {
    const deliveryAddress = getCookie(COOKIE.DELIVERY_ADDRESS_NAME);
    const closeGeoSessionStorage = sessionStorage.getItem('closeGeo');
    if (!deliveryAddress && !closeGeoSessionStorage && !router?.query?.login) {
      Emmiter.emit(EVENTS.OPEN_GEO_MODAL);
    }
  }, []);

  return (
    <div className={styles.layoutWrapper}>
      <SeoHead seo={seo} />
      <Navigation mobileHeader={mobileHeader} searchInput={searchInput} />
      {children}
      <GEO></GEO>
      <Authorization />
      <FarmerRegistration></FarmerRegistration>
      <ModalDeliveryDay />
      <NavigationMobile />
      <MobileNavigationTabs />
      <div className={styles.footerWrapper}>{!noFooter && <Footer searchPage={searchPage} />}</div>
    </div>
  );
};

Layout.defaultProps = {
  noFooter: false,
  seo: null,
};

Layout.propTypes = {
  noFooter: PropTypes.bool,
  seo: PropTypes.shape(),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object])
    .isRequired,
};

export default Layout;
