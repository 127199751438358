import React from 'react';
import Link from 'next/link';
import Button from '../../Button';
import Layout from '../../Layout/Layout';
import routes from '../../../helpers/routes';
import VectorRightIcon from '../../../../public/icons/vector-right.svg';
import styles from './Error404.module.scss';

const Error404 = () => (
  <Layout>
    <div className={styles.content}>
      <div className={styles.wrap}>
        {/* <img src="/icons/sheet.svg" /> */}
        <h1 className={styles.title}>Ошибка 404</h1>
        <div className={styles.text}>
          К сожалению, запрашиваемая Вами страница не найдена. Вероятно, Вы указали несуществующий адрес,
          страница была удалена, перемещена или сейчас она временно недоступна!
        </div>
        <Link href={routes.root}>
          <Button className={styles.mainBtn} tag="a" rounded increased uppercase>
            На главную
            <VectorRightIcon />
          </Button>
        </Link>
      </div>
    </div>
  </Layout>
);

export default Error404;
