import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/nextjs'
import ErrorPageContent from '../Error404';

const ErrorPage = ({ statusCode, hasGetInitialPropsRun, err }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
  }

  return <ErrorPageContent statusCode={statusCode} />;
};

ErrorPage.propTypes = {
  err: PropTypes.shape().isRequired,
  statusCode: PropTypes.number.isRequired,
  hasGetInitialPropsRun: PropTypes.bool.isRequired,
};

export default ErrorPage;
